import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './LangHeader.css';
import '../../../../i18n';

function LangHeader() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem("language") || "en");
    const [openLangBody, setOpenLangBody] = useState('0');

    useEffect(() => {
        const savedLanguage = localStorage.getItem("language");
        if (savedLanguage && savedLanguage !== i18n.language) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    useEffect(() => {
        if (language) {
            localStorage.setItem("language", language);
            i18n.changeLanguage(language);
        }
    }, [language, i18n]);

    const changeLanguage = (lang) => {
        setLanguage(lang);
    };

    const toggleHeightLanguage = () => {
        if(openLangBody === '0') {
            setOpenLangBody('70')
        }
        else {
            setOpenLangBody('0');
        }
    };

    return (
        <div className='LangHeaderWrapper'>
            <div className="LangHeader" onClick={toggleHeightLanguage}>
                <div className="LangHeaderBody" style={{ height: openLangBody + 'px'}}>
                    <div className="LangHeaderBodyItem">
                        <p>{t("Language")}</p>
                        <select
                            value={language}
                            onChange={(e) => {
                                const selectedLang = e.target.value;
                                changeLanguage(selectedLang);
                            }}
                            className="LangSelector"
                        >
                            <option value="en">English</option>
                            <option value="ru">Русский</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LangHeader;
