import { useDropzone } from "react-dropzone";
import React, {useState} from "react";

import './Upload.css';

function Upload() {

    const [files, setFiles] = useState([]);
    const [requestFiles, setRequestFiles] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('');

    const [onDrag, setOnDrag] = useState('upload');

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'video/*',
        onDrop: (acceptedFiles) => {
            setUploadStatus('')
            setRequestFiles(acceptedFiles);
            console.log(acceptedFiles);
            let dataFiles = acceptedFiles.map(file => ({
                name: file.name,
                size: file.size,
                type: file.type,
                preview: URL.createObjectURL(file),
            }))
            setFiles(dataFiles)
        },
        onDragEnter: () => {
            setOnDrag('uploadd');
        },
        onDragLeave: () => {
            setOnDrag('upload');
        }

    });

    const HandlerSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        requestFiles.forEach((file) => {
            formData.append("files", file);
        });
        try{
            let response = await fetch(process.env.REACT_APP_API_FILES + '/upload', {
                method: 'POST',
                body: formData,
                credentials: 'include',
            })
            if (response.ok) {
                setUploadStatus("File uploaded successfully!");
                setFiles([])
            } else {
                setUploadStatus("File upload failed.");
            }
        }
        catch(err){
            setUploadStatus("An error occurred during the upload.");
        }
    }



    return (
        <div className="Upload">
            <p>{uploadStatus}</p>
            <div className={onDrag} {...getRootProps()}>
                <input {...getInputProps()} accept="video/mp4, video/webm, video/ogg"/>
                <span>Перетащите видео сюда или нажмите для выбора</span>
            </div>
            <button onClick={HandlerSubmit}>Загрузить</button>
        </div>

    );
}

export default Upload;