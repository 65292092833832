import React, {useContext} from "react";

import {AuthContext} from "../../../../globalParameters/AuthProvider";
import './LogoutButton.css'

function LogoutButton() {

    const { isAuthState, setIsAuthState } = useContext(AuthContext);

    const logout = () => {
        fetch(process.env.REACT_APP_API_AUTH + "/logout", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
        });
        setIsAuthState('false');
    };



    return (
        <div className="LogoutButtonWrapper">
            <button onClick={logout} className='ButtonLogout'></button>
        </div>
    )

}

export default LogoutButton;