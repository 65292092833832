import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import './Header.css';
import Navigation from "./components/Navigation/Navigation";
import LangHeader from "./components/LangHeader/LangHeader";
import LogoutButton from "./components/LogoutButton/LogoutButton";
import { AuthContext } from "../../globalParameters/AuthProvider";
import PanelNavigation from "./components/PanelNavigation/PanelNavigation";
import ThemeButton from "./components/ThemeButton/ThemeButton";
import { useGeneralContext } from "../../globalParameters/GeneralContext";

function Header() {
    const [style, setStyle] = useState('white');
    const { theme } = useGeneralContext();
    const navigate = useNavigate();
    const { isAuthState } = useContext(AuthContext);

    useEffect(() => {
        if (theme === "light") {
            setStyle('#999');
        } else if (theme === "dark") {
            setStyle('#131313');
        }
    }, [theme]); // Срабатывает только при изменении `theme`

    // Рендеринг для разных состояний авторизации
    if (isAuthState !== 'true') {
        return (
            <div className="Header" style={{ backgroundColor: style }}>
                <div className="HeaderWarp">
                    <div className="HeaderLogo">
                        <img src={'/images/newLogo2.png'} alt="logo" onClick={() => navigate("/")} />
                    </div>
                    <Navigation />
                    <div className='EmptyBlock' />
                    <LangHeader />
                </div>
            </div>
        );
    } else {
        return (
            <div className="Header" style={{ backgroundColor: style }}>
                <div className="HeaderWarp">
                    <div className="HeaderLogo">
                        <img src={'/images/newLogo2.png'} alt="logo" onClick={() => navigate("/")} />
                    </div>
                    <PanelNavigation />
                    <div className='EmptyBlock' />
                    <ThemeButton />
                    <LangHeader />
                    <LogoutButton />
                </div>
            </div>
        );
    }
}

export default Header;
