import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';  // Импортируем хук
import './Navigation.css';
import {Link} from "react-router-dom";

function Navigation() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    useEffect(() => {
        setLanguage(i18n.language);
    }, [i18n.language]);

    const changeLanguage = async (lang) => {
        await i18n.changeLanguage(lang);
        setLanguage(lang);
    };

    return (
        <div className="Navigation">
            <ul>
                <li>
                    <Link to="/login" style={{ textDecoration: 'none' }}>
                        <p>{t('Login')}</p>  {/* Используем функцию t для перевода */}
                    </Link>
                </li>
                <li>
                    <Link to="/registration" style={{ textDecoration: 'none' }}>
                        <p>{t('Register')}</p>  {/* Используем функцию t для перевода */}
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default Navigation;
