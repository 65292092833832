import React from 'react';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegistrationPage from './pages/RegistrationPage/RegistrationPage';
import './styles/App.css';
import { GeneralProvider } from "./globalParameters/GeneralContext";
import { PrivateRoute } from "./globalParameters/PrivateRoute";
import { AuthProvider } from "./globalParameters/AuthProvider";
import MyVideoPage from "./pages/MyVideoPage/MyVideoPage";
import './i18n';

function App() {
    return (
        <AuthProvider>
            <GeneralProvider>
                <div className="App">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={
                                <PrivateRoute>
                                    <HomePage />
                                </PrivateRoute>
                            } />
                            <Route path="/myvideos" element={
                                <PrivateRoute>
                                    <MyVideoPage />
                                </PrivateRoute>
                            } /> {/* Добавил закрывающую скобку здесь */}
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/registration" element={<RegistrationPage />} />
                        </Routes>
                    </BrowserRouter>
                </div>
            </GeneralProvider>
        </AuthProvider>
    );
}

export default App;
