import React, { useState, useEffect } from "react";

import './HomePage.css';
import Header from "../../components/Header/Header";
import { useGeneralContext } from "../../globalParameters/GeneralContext";

function HomePage() {
    const { theme } = useGeneralContext(); // Доступ к текущей теме
    const [backgroundColor, setBackgroundColor] = useState('white');

    // Изменение цвета фона при смене темы
    useEffect(() => {
        if (theme === "light") {
            setBackgroundColor('white');
        } else if (theme === "dark") {
            setBackgroundColor('#282c34');
        }
    }, [theme]); // Следим за изменением темы

    console.log(process.env.REACT_APP_API_AUTH);

    return (
        <div className="homePage" style={{ backgroundColor }}>
            <Header />
        </div>
    );
}

export default HomePage;
