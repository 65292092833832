import React, { useContext, useState } from "react";
import './LoginForm.css';
import { AuthContext } from "../../../globalParameters/AuthProvider";
import {useTranslation} from "react-i18next";

function LoginForm() {

    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const { isAuthState } = useContext(AuthContext);
    if (isAuthState) {
        console.log(isAuthState);
    }

    const sendLogin = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_AUTH + "/authorization", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username: username, password: password }),
                credentials: 'include',
            });

            const data = await response.json();

            if (data.success) {
                window.location.reload();
            } else {
                console.log('Login failed:', data.message);
            }
        } catch (e) {
            console.log('Error:', e.message);
        }
    };

    return (
        <div className='BlockLoginForm'>
            <div className='LoginForm'>
                <div className='LoginFormLogo'>
                    <img src='/images/logo.png' alt='logo' />
                </div>

                <h3>{t('Welcome')}</h3>

                <form className='LoginForm'>
                    <label htmlFor='email'><p>{t('LoginF')}</p></label>
                    <input className='LoginForm__input' type='login' onChange={(e) => setUsername(e.target.value)} />
                    <label htmlFor='password'><p>{t('Password')}</p></label>
                    <input className='LoginForm__input' type='password' onChange={(e) => setPassword(e.target.value)} />
                </form>

                <input type="submit" value={t('Login')} onClick={sendLogin} />
            </div>
        </div>
    );
}

export default LoginForm;
