import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

export function PrivateRoute({ children }) {
    const { isAuthState } = useContext(AuthContext);
    console.log(isAuthState);
    if (isAuthState === null) {
        return <div>Loading...</div>;
    }

    if (isAuthState === 'true') {
        return children;
    } else {
        return <Navigate to="/login" />;
    }
}
