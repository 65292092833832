import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import './LoginPage.css';
import LoginForm from "./components/LoginForm";
import Header from "../../components/Header/Header";
import { AuthContext } from "../../globalParameters/AuthProvider";

function LoginPage() {
    const { isAuthState } = useContext(AuthContext);

    useEffect(() => {
        if (isAuthState === 'true') {
            window.location.href = '/';
        }
    }, [isAuthState]);

    if (isAuthState === 'true') {
        return <Navigate to="/" />;
    }

    return (
        <div className="LoginPage">
            <Header />
            <LoginForm />
        </div>
    );
}

export default LoginPage;
