import React from "react";
import { useGeneralContext } from "../../../../globalParameters/GeneralContext";
import './ThemeButton.css';

function ThemeButton() {
    const { theme, setTheme } = useGeneralContext();

    return (
        <div
            className="ThemeButton"
            style={{
                backgroundImage: `url(${theme === "dark" ? "images/DarkTheme.png" : "images/LightTheme.png"})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
            }}
        >
            <button
                onClick={() => {
                    const newTheme = theme === "dark" ? "light" : "dark";
                    setTheme(newTheme);
                    console.log(`Theme changed to: ${newTheme}`);
                }}
            >
            </button>
        </div>
    );
}

export default ThemeButton;
