import React, { useState } from "react";
import './RegistrationForm.css';
import { useTranslation } from "react-i18next";

function RegistrationForm() {
    const { t } = useTranslation();
    const [loginForm, setLoginForm] = useState('');
    const [emailForm, setLoginEmail] = useState('');
    const [passwordForm, setLoginPassword] = useState('');
    const [confirmPasswordForm, setConfirmPasswordForm] = useState('');

    const request = async () => {
        if (!loginForm || !emailForm || !passwordForm) {
            alert(t("FillAllFields"));
            return;
        }

        if (passwordForm !== confirmPasswordForm) {
            alert(t("PasswordsDoNotMatch"));
            return;
        }

        const requestData = {
            login: loginForm,
            email: emailForm,
            password: passwordForm
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_AUTH + '/registration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Server error details:', errorData);
            } else {
                const data = await response.json();
                console.log('Registration successful:', data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='BlockRegistrationForm'>
            <div className='RegistrationForm'>
                <div className='RegistrationFormLogo'>
                    <img src='/images/logo.png' alt='logo'/>
                </div>

                <h3>{t('RegWelcome')}</h3>

                <form className='RegistrationForm'>
                    <label htmlFor='login'>{t("LoginF")}</label>
                    <input
                        type='text'
                        id='login'
                        name='login'
                        onChange={e => setLoginForm(e.target.value)}
                        value={loginForm}
                        autoComplete='username'
                    />

                    <label htmlFor='email'>{t("E-mail")}</label>
                    <input
                        type='email'
                        id='email'
                        name='email'
                        onChange={e => setLoginEmail(e.target.value)}
                        value={emailForm}
                        autoComplete='email'
                    />

                    <label htmlFor='password'>{t("Password")}</label>
                    <input
                        type='password'
                        id='password'
                        name='password'
                        onChange={e => setLoginPassword(e.target.value)}
                        value={passwordForm}
                        autoComplete='new-password'
                    />

                    <label htmlFor='confirm-password'>{t("ConfirmPassword")}</label>
                    <input
                        type='password'
                        id='confirm-password'
                        name='confirm-password'
                        onChange={e => setConfirmPasswordForm(e.target.value)}
                        value={confirmPasswordForm}
                        autoComplete='new-password'
                    />
                </form>

                <input type="submit" value={t('Register')} onClick={request}/>
            </div>
        </div>
    );
}

export default RegistrationForm;
