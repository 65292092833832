import React, {useContext} from "react";

import './RegistrationPage.css';
import Header from "../../components/Header/Header";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm";
// import TelegramLogin from "../../components/TelegramLogin/TelegramLogin";
import {AuthContext} from "../../globalParameters/AuthProvider";
import {Navigate} from "react-router-dom";

function RegistrationPage() {

    const { isAuthState } = useContext(AuthContext);

    console.log(isAuthState)
    if (isAuthState === 'true'){
        return <Navigate to='/' />
    }
    else {
        return (
                <div className='RegistrationPage'>
                    <Header />
                    <RegistrationForm />
                </div>
            );
    }
}

export default RegistrationPage;
