import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Импортируем хук t для перевода

import './PanelNavigation.css'

function PanelNavigation() {
    const { t } = useTranslation(); // Получаем функцию t для перевода

    return (
        <div className="PanelNavigation">
            <ul>
                <li>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <p>{t("Home")}</p> {/* Используем функцию t для перевода */}
                    </Link>
                </li>
                <li>
                    <Link to="/myvideos" style={{ textDecoration: 'none' }}>
                        <p>{t("My Videos")}</p> {/* Используем функцию t для перевода */}
                    </Link>
                </li>
                <li>
                    <Link to="/myplayer" style={{ textDecoration: 'none' }}>
                        <p>{t("My Player")}</p> {/* Используем функцию t для перевода */}
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default PanelNavigation;
