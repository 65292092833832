import React, { useState, useEffect } from "react";

import './MyVideoPage.css';
import Header from "../../components/Header/Header";
import Upload from "./components/Upload/Upload";
import { Theme } from "../../globalParameters/Theme";
import { useGeneralContext } from "../../globalParameters/GeneralContext";

function MyVideoPage() {
    const { theme } = useGeneralContext();
    const [backgroundColor, setBackgroundColor] = useState('white');

    useEffect(() => {
        if (theme === "light") {
            setBackgroundColor('white');
        } else if (theme === "dark") {
            setBackgroundColor('#282c34');
        }
    }, [theme]);

    return (
        <div className="MyVideoPage" style={{ backgroundColor }}>
            <Header />
            <Upload />
        </div>
    );
}

export default MyVideoPage;
