import React, { useState, useEffect, createContext } from 'react'; // Добавьте useContext

export const AuthContext = createContext(); // Экспортируем AuthContext как named export

export function AuthProvider({ children }) {
    const [isAuthState, setIsAuthState] = useState(null);

    useEffect(() => {
        const resAuth = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_AUTH + '/authentication', {
                    method: 'GET',
                    credentials: 'include'
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    setIsAuthState(data.status);
                    console.log(data);
                }
            } catch (err) {
                console.log(err);
            }
        };
        resAuth();

    }, [isAuthState]);

    return (
        <AuthContext.Provider value={{ isAuthState, setIsAuthState }}>
            {children}
        </AuthContext.Provider>
    );
}
