import React, { createContext, useContext, useState, useEffect } from "react";

const GeneralContext = createContext();

function GeneralProvider({ children }) {
    const [authenticated, setAuthenticated] = useState(false);

    const [theme, setThemeState] = useState(() => localStorage.getItem("theme") || "light");

    const setTheme = (value) => {
        localStorage.setItem("theme", value);
        setThemeState(value);
    };

    useEffect(() => {
        const storedTheme = localStorage.getItem("theme");
        if (storedTheme) {
            setThemeState(storedTheme);
        }
    }, []);

    return (
        <GeneralContext.Provider value={{ authenticated, setAuthenticated, theme, setTheme }}>
            {children}
        </GeneralContext.Provider>
    );
}

function useGeneralContext() {
    const context = useContext(GeneralContext);
    if (!context) {
        throw new Error("useGeneralContext must be used within a GeneralProvider");
    }
    return context;
}

export { GeneralProvider, useGeneralContext };
